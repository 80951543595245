<template>
    <div>
        <input ref="gallery_image_upload" type="file"
            accept="image/x-png,image/gif,image/jpeg,application/json,.json,image/svg+xml,.svg,.lottie" class="d-none"
            @change="ImageRendererItems" />
        <b-button variant="primary" :disabled="isLoading" class="mr-2 d-flex" @click="triggerFileInput">
            <div class="d-flex">
                <b-spinner v-if="isLoading" small style="margin-right: 5px;" label="Loading..." />
                Upload
            </div>
        </b-button>
    </div>
</template>
<script>
import {
    BButton,
    BSpinner
} from "bootstrap-vue";
import axios from "axios";
export default {
    components: {
        BButton,
        BSpinner
    },
    data() {
        return {
            bearerToken: null,
            isLoading: false,
        };
    },
    created() {
        this.bearerToken = "Bearer " + localStorage.getItem("_t");
    },
    methods: {
        triggerFileInput() {
            this.$refs['gallery_image_upload'].click();
        },
        async uploadFile(file, url, key, ext) {
            try {
                const response = await axios.put(url, file, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': file.type,
                    },
                });

                if (response.status !== 200) {
                    console.error('Error uploading file:', response);
                }
                let s3Url = 'https://zape02.s3.ap-southeast-1.amazonaws.com/' + key;
                this.$emit("saveUploadImage", s3Url);
                this.createGalleryImage(s3Url, file.name, ext);
            } catch (error) {
                this.isLoading = false;
                console.error('Error uploading file:', error);
            }
        },
        async createGalleryImage(s3Url, fileName, ext) {
            console.log('s3Url', s3Url)
            try {
                let galleryObj = {
                    name: fileName,
                    url: s3Url,
                    imageFormat: ext
                }
                await axios.post(process.env.VUE_APP_API_URL + '/gallery/image/create', galleryObj, {
                    headers: {
                        Authorization: this.bearerToken,
                    },
                });
                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;
                console.error('Error saving image to gallery', error);
            }
        },
        async ImageRendererItems(event) {
            const file = event.target.files[0];
            if (file) {
                this.isLoading = true;
                const ext = file.name.split('.').pop().toLowerCase();

                // const fileType = file.type;
                let fileExtension;
                if (ext === 'lottie') {
                    fileExtension = 'lottie'
                } else {
                    fileExtension = this.getFileExtension(file.type);
                }

                try {
                    // const fileType = file.type;
                    // const fileExtension = this.getFileExtension(fileType);
                    const response = await axios.post(process.env.VUE_APP_API_URL + '/s3/generate-presigned-url', {
                        type: file.type,
                        ext: fileExtension
                    }, {
                        headers: {
                            Authorization: this.bearerToken,
                        },
                    });
                    const { url, key } = response.data;
                    let extention = fileExtension === 'json' ? 'lottie' : fileExtension
                    await this.uploadFile(file, url, key, extention);
                } catch (error) {
                    this.isLoading = false;
                    console.error('Error getting pre-signed URL:', error);
                }
            }
        },
        getFileExtension(mimeType) {
            const mimeToExtension = {
                'image/jpeg': 'jpg',
                'image/png': 'png',
                'image/gif': 'gif',
                'image/svg+xml': 'svg',
                '.svg': 'svg',
                'application/json': 'json',
                '.json': 'json',
                '.lottie': 'lottie',
                'lottie': 'lottie',
            };
            return mimeToExtension[mimeType] || 'png';
        },
    },
};
</script>
